import store from "../store";

const URL_API =
    process.env.NODE_ENV === "development"
        ? "http://localhost:4008/"
        : "https://enfoquelaboral.mx/";

const requestFetch = async (path, data) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", store.getters["auth/getToken"]);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: data ? "POST" : "GET",
        headers: myHeaders,
        body: JSON.stringify(data) || null,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${URL_API}${path}`, requestOptions);
        const data = await response.json();

        if (data.error) throw data.error;
        return data;
    } catch (error) {
        console.log(error);
        return { error };
    }
};

export default {
    namespaced: true,
    state: {
        errors: [],
    },
    mutations: {
        setErrors(state, error) {
            if (error == "clear") {
                state.errors = [];
            } else {
                state.errors.push(error);
            }
        },
    },
    actions: {
        //SET ERRORS
        setErrors({ commit }, error) {
            commit("setErrors", error);
        },

        async createOrder({ commit }, order) {
            commit("setErrors", "clear");
            const response = await requestFetch("api/orders", order);
            // console.log(response)
            if (response.error) commit("setErrors", response.error);
            return response.order;
        },

        async updateOrder({ commit }, { id, data }) {
            // console.log(id, reset);
            commit("setErrors", "clear");
            const response = await requestFetch(`api/orders/${id}`, data);
            // console.log(response);
            if (response.error) commit("setErrors", response.error);
        },

        async deleteOrder({ commit }, order_id) {
            console.log('eliminar order');
            // console.log(order_id)
            commit("setErrors", "clear");
            const response = await requestFetch(`api/orders/delete/${order_id}`);
            // console.log(response);
            if (response.error) commit("setErrors", response.error);
        },



        // async getOrder({ commit }, id) {
        //     commit("setErrors", "clear");
        //     const response = await requestFetch(`api/channels/${id}`);
        //     if (response.error) commit("setErrors", response.error);
        //     return response.channel;
        // },


    },
    getters: {
        getErrors(state) {
            return state.errors;
        },
    },
};
