import store from "../store";
import axios from 'axios'
const URL_API =
    process.env.NODE_ENV === "development"
        ? "http://localhost:4008/"
        : "produccion";

const requestFetch = async (path, data) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", store.getters["auth/getToken"]);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: data ? "POST" : "GET",
        headers: myHeaders,
        body: JSON.stringify(data) || null,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${URL_API}${path}`, requestOptions);
        const data = await response.json();

        if (data.error) throw data.error;
        return data;
    } catch (error) {
        console.log(error);
        return { error };
    }
};

export default {
    namespaced: true,
    state: {
        errors: [],
    },
    mutations: {
        setErrors(state, error) {
            if (error == "clear") {
                state.errors = [];
            } else {
                state.errors.push(error);
            }
        },
    },
    actions: {
        //SET ERRORS
        setErrors({ commit }, error) {
            commit("setErrors", error);
        },

        async getDocumentsByProduct({ commit }, id) {
            commit("setErrors", "clear");
            // console.log(id)
            const response = await requestFetch(`api/products/${id}/documents`);
            // console.log(response)
            return response.documents;

        },

        async downloadFile({ commit }, id) {
            commit("setErrors", "clear");
            try {
                const resp = await axios({
                    url: `${URL_API}api/files/${id}`,
                    method: "GET",
                    responseType: "blob",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": store.getters["auth/getToken"]

                    },
                });
                return resp

            } catch (error) {
                console.log(error)
                commit("setErrors", error.response)
            }

        },
    },
    getters: {
        getErrors(state) {
            return state.errors;
        },
    },
};
