import {conektaAPI} from "../api/conekta";
import store from "../store";
import moment from 'moment'

const URL_API =
    process.env.NODE_ENV === "development"
        ? "http://localhost:4008/"
        : "https://enfoquelaboral.mx/";
const requestFetch = async (path, method, data) => {
    const myHeaders = new Headers();
    myHeaders.append("authorization", store.getters["auth/getToken"]);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: method,
        mode: "cors",
        headers: myHeaders,
        body: JSON.stringify(data) || null,
        redirect: "follow",
    };
    try {
        const response = await fetch(`${URL_API}${path}`, requestOptions);
        const data = await response.json();

        if (data.error) throw data.error;
        return data;
    } catch (error) {
        return {error};
    }
};

export default {
    namespaced: true,
    state: {
        errors: [],
        is_success: false,
        membership: null,
        isLoading: false
    },
    mutations: {
        setIsSuccess(state, value) {
            state.is_success = value;
        },
        setMembership(state, membership) {
            state.membership = membership;

        },
        setErrors(state, error) {
            if (error == "clear") {
                state.errors = [];
            } else {
                state.errors.push(error);
            }
        },
    },
    actions: {
        setIsSuccess({commit}, value) {
            commit("setIsSuccess", value);
        },
        //SET ERRORS
        setErrors({commit}, error) {
            commit("setErrors", error);
        },

        // obtener mi membresia
        async getMyMembership({commit, dispatch}) {

            commit("setErrors", "clear");
            if (!store.getters['auth/getUser']) {
                commit('setMembership', null)
            } else {
                const response = await requestFetch("api/user_membership");
                if (response.membreship) {


                    if (!response.membreship.is_company) {

                        const validate_date = new Date(moment(response.membreship.end_date).format('YYYY-MM-DD')) < new Date(moment().format('YYYY-MM-DD'))
                        const data = {
                            customer_id: response.membreship.user.UserBroker.conekta,
                            membership_id: response.membreship.id,
                        };
                        if (validate_date) {
                            dispatch('calcelMyMembership', data)
                        }
                    }
                }
                commit('setMembership', response.membreship)
                return response.membreship;
            }
        },
        // cancelar mi membresia
        async calcelMyMembership({commit}, {customer_id, membership_id}) {
            console.log('cancel_subscription conekta')
            const body = {
                "customer_info": {
                    customer_id
                }
            }
            const response = await requestFetch(`api/cancel_user_membership/${membership_id}`);
            const resp = await conektaAPI.post(`/cancel_subscription`, body)

            // console.log(response)
            if (resp.data.error) {
                return commit("setErrors", resp.data.error);
            }
            if (response.error) commit("setErrors", response.error);

        },

    },
    getters: {
        getMembership(state) {
            return state.membership;
        },
        getIsSuccess(state) {
            return state.is_success;
        },

        getErrors(state) {
            return state.errors;
        },
    },
};
