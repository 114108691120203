<template>
  <div class="col-md-5 col-lg-4 col-sm-6" v-if="product">
    <div class="pricing-item">
      <ul class="px-4 px-md-6">
        <li class="pricing-header">
          <h4>{{ getDate }}</h4>
          <h2>{{ product.price | formatCurrencyIso }}</h2>
          <!-- <span>Por Mes</span> -->
        </li>
        <li
          class="text-center"
          v-for="(item, index) in product.details"
          :key="index"
        >
          {{ item.description }}
        </li>
        <li class="footer">
          <!-- <a class="btn btn-dark border btn-sm" @click="addServiceTocart"
            >Adquirir Membresia</a
          > -->
          <pay-button
            v-if="!hiddenBtnPay"
            class="btn btn-pay btn-dark border btn-sm"
            :product="product"
          >
            <span class="title-pay">Adquirir Membresia</span>
          </pay-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { formatCurrencyIso } from "../../filters/currency";
import PayButton from "../PayButton.vue";
import { mapGetters } from "vuex";
export default {
  components: { PayButton },
  props: {
    product: {
      type: Object,
    },
  },

  filters: {
    formatCurrencyIso,
  },

  computed: {
    getDate() {
      const value = this.product.membership.days;
      switch (true) {
        case value <= 30:
          return "1 Mes";
        case value > 30 && value <= 90:
          return "3 Meses";
        case value > 90 && value <= 180:
          return "6 Meses";
        case value > 180 && value <= 365:
          return "1 Año";

        default:
          return "nada";
          break;
      }
    },
    ...mapGetters("membership", ["getMembership"]),
    hiddenBtnPay() {
      if (this.getMembership) {
        return this.getMembership.order.details.product.id == this.product.id;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.title-pay {
  color: black;
}
.title-pay:hover {
  color: white;
}

.btn-pay:hover .title-pay {
  color: white;
}
</style>