import store from "../store";
import router from "../router/index";
import axios from 'axios'
const URL_API =
    process.env.NODE_ENV === "development"
        ? "http://localhost:4008/"
        : "https://enfoquelaboral.mx/";

// const URL_API_CONEKTA = 'https://payment.enfoquelaboral.mx/conekta';
import { conektaAPI } from '../api/conekta'

const requestFetch = async (path, data) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", store.getters["auth/getToken"]);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: data ? "POST" : "GET",
        headers: myHeaders,
        body: JSON.stringify(data) || null,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${URL_API}${path}`, requestOptions);
        const data = await response.json();

        if (data.error) throw data.error;
        return data;
    } catch (error) {
        console.log(error);
        return { error };
    }
};

export default {
    namespaced: true,
    state: {
        errors: [],
    },
    mutations: {
        setErrors(state, error) {
            if (error == "clear") {
                state.errors = [];
            } else {
                state.errors.push(error);
            }
        },


    },
    actions: {
        //SET ERRORS
        setErrors({ commit }, error) {
            commit("setErrors", error);
        },




        async addCard({ commit, dispatch }, { token_id, customer_id }) {
            const dev = false
            commit("setErrors", "clear");
            console.log('agregar tarjeta')
            console.log(token_id, 'token_id')
            // console.log(token_id)
            // console.log(customer_id)
            const body = {
                "customer_info": {
                    token_id: dev ? "tok_test_card_declined" : token_id,
                    type: "card",
                    customer_id
                }
            }
            const resp = await conektaAPI.post(`/add_card`, body)
            // console.log(resp)
            if (resp.data.error) {
                return commit("setErrors", resp.data.error);
            }
            return resp.data.msg.id;
        },

        async changePrimaryCard({ commit }, { card_id, customer_id }) {
            commit("setErrors", "clear");

            console.log('tarjeta principal')
            const body = {
                "customer_info": {
                    card_id,
                    customer_id
                }
            }
            const resp = await conektaAPI.post(`/change_primary_card`, body)
            // console.log(resp)

            if (resp.data.error) {
                return commit("setErrors", resp.data.error);
            }
        },

        async createSuscription({ commit }, { plan_id, customer_id }) {

            const body = {
                "customer_info": {
                    plan_id,
                    customer_id
                }
            }
            const resp = await conektaAPI.post(`/create_subscription`, body)
            console.log(resp)

            if (resp.data.error) {
                return commit("setErrors", resp.data.error);
            }
            return resp
        },
        async deleteCard({ commit, dispatch }, card_id) {
            commit("setErrors", "clear");
            console.log('eliminar tarjeta')
            const customer_id = store.getters["auth/getUser"].UserBroker.conekta

            const body = {
                "customer_info": {
                    "card_id": card_id,
                    "customer_id": customer_id
                }
            }
            const resp = await conektaAPI.post(`/delete_card`, body)
            console.log(resp)

            if (resp.data.error) {
                return commit("setErrors", resp.data.error);
            }
            // asignar tarjeta principal
            // dispatch('changePrimaryCard', resp.data.msg.id)
        },

        async updateProfile({ commit }, data) {
            commit("setErrors", "clear");
            const body = {
                "customer_info": {
                    "name": data.name,
                    "email": data.email,
                    "phone": data.phone
                }
            }
            const resp = await conektaAPI.post(`/create_customer`, body)
            // console.log(resp)
            if (resp.data.error) {
                return resp.data;
                // return commit("setErrors", resp.data.error);
            }
            const { id } = resp.data.customer
            const response = await requestFetch("api/auth/update_profile", { data, id });
            // console.log(response);
            if (response.error) commit("setErrors", response.error);
            await store.dispatch('auth/userme')
            // router.push({ name: 'MembershipCheckout' })

            return response.data;
        },


    },
    getters: {
        getErrors(state) {
            return state.errors;
        },
        getTokenCard(state) {
            return state.tokenCard;
        },

    },
};
