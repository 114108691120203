import store from "../store";
import router from "../router";
import { conektaAPI } from "../api/conekta";
const URL_API =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4008/"
    : "https://enfoquelaboral.mx/";
const requestFetch = async (path, method, data) => {
  const myHeaders = new Headers();
  myHeaders.append("authorization", store.getters["auth/getToken"]);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: method,
    mode: "cors",
    headers: myHeaders,
    body: JSON.stringify(data) || null,
    redirect: "follow",
  };
  try {
    const response = await fetch(`${URL_API}${path}`, requestOptions);
    const data = await response.json();

    if (data.error) throw data.error;
    return data;
  } catch (error) {
    return { error };
  }
};

export default {
  namespaced: true,
  state: {
    errors: [],
    token: "",
    user: null,
    is_success: false,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },

    setIsSuccess(state, value) {
      state.is_success = value;
    },

    setErrors(state, error) {
      if (error == "clear") {
        state.errors = [];
      } else {
        state.errors.push(error);
      }
    },
  },
  actions: {
    //SET ERRORS
    setIsSuccess({ commit }, value) {
      commit("setIsSuccess", value);
    },
    //SET ERRORS
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    //SET ERRORS
    setToken({ commit }, token) {
      commit("setToken", token);
    },
    //SET ERRORS
    setErrors({ commit }, error) {
      commit("setErrors", error);
    },

    async login({ commit }, user) {
      const response = await requestFetch(`api/auth/login`, "POST", user);
      // console.log(response)
      // console.log(response);
      if (response.error) return commit("setErrors", response.error);

      commit("setUser", response.user);
      commit("setToken", "Bearer " + response.access_token);
      // console.log(router)
      if (router.history.current.query.pay) {
        router.push({ name: "MembershipCheckout" });

      } else {
        // router.push({ name: "Dashboard" });
        window.location.href = "/dashboard";

      }
    },

    async loginSocial({ commit }, { token, provider }) {
      // router.post('/api/auth/login_social_network',[
      const body = {
        provider,
        token
      };

      // console.log(body);

      const response = await requestFetch(
        `api/auth/login_social_network`,
        "POST",
        body
      );
      // console.log(response);
      if (response.error) return commit("setErrors", response.error);

      commit("setUser", response.user);
      commit("setToken", "Bearer " + response.access_token);
      // router.push({ name: "Dashboard" });
      // window.location.href = '/dashboard'
      if (router.history.current.query.pay) {
        router.push({ name: "MembershipCheckout" });

      } else {
        // router.push({ name: "Dashboard" });
        window.location.href = "/dashboard";

      }
    },



    async getUserAdminRandom({ commit }) {
      commit("setErrors", "clear");
      const response = await requestFetch("api/auth/user/random");
      return response.user;
    },




    async recover_password({ commit }, { email }) {
      // console.log(email);
      // const response = await requestFetch("api/auth/recover_password", "POST", {
      //   email,
      // });
      const data = {
        email,
      };
      const response = await requestFetch(
        `api/auth/recover_password`,
        "POST",
        data
      );

      // console.log(response);

      if (response.msg) {
        return true;
      }
      if (response.error) return commit("setErrors", response.error);
    },

    async register({ commit }, user) {
      // console.log(user);
      const data = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
        password: user.password,
        password_confirmation: user.password_confirmation,
      };
      // console.log(data);

      const response = await requestFetch(`api/auth/register`, "POST", data);
      // console.log(response);
      if (response.msg) {
        router.push({ name: "Login" });
        commit("setIsSuccess", true);
        return true;
      }
      if (response.error) return commit("setErrors", response.error);
    },

    async logout({ commit }) {
      const response = await requestFetch("api/auth/logout", "GET");

      commit("setUser", null);
      commit("setToken", "");
      sessionStorage.removeItem("channelStorage");
      await router.push({ name: "Login" });

      // store.dispatch['membreship/getMyMembership']

      if (router.currentRoute.fullPath !== "/") await router.push("/");
    },



    async userme({ commit }) {
      // console.log('get profile')
      let response;

      if (!store.getters["auth/getToken"]) {
        commit("setUser", null);
      } else {
        response = await requestFetch(`api/user/me`);
        // console.log(response)
        if (response.error) {
          commit("setUser", null);
          commit("setToken", null);
        }
        commit("setUser", response.user);
        // console.log(store.getters['auth/getUser'])
      }

      return response;
    },

  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getToken(state) {
      return state.token;
    },
    getErrors(state) {
      return state.errors;
    },
  },
};
