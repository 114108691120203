import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const ls = new SecureLS({
  isCompression: false,
});

Vue.use(Vuex);
import auth from "../modules/auth";
import calendar from "../modules/calendar";
import channel from "../modules/channel";
import message from "../modules/message";
import product from "../modules/product";
import comment from "../modules/comment";
import cart from "../modules/cart";
import card from "../modules/card";
import membership from "../modules/membership";
import order from "../modules/order";
import document from "../modules/document";
import payment from "../modules/payment";
import blog from "../modules/blog";


export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],

  state: {
    beforeRoute: "/",
    draw: false,
    over: false,
    messages: [],
  },
  mutations: {
    setBeforeRoute(state, beforeRoute) {
      state.beforeRoute = beforeRoute;
    },
    setDraw(state, draw) {
      state.draw = draw ? draw : !state.draw;
    },
    setOver(state, over) {
      state.over = over;
    },
    setMessages(state, message) {
      if (message === "clear") {
        state.messages = [];
      } else {
        state.messages.push(message);
      }
    },
  },
  actions: {
    setBefore({ commit }, beforeRoute) {
      commit("setBeforeRoute", beforeRoute);
    },
    setDraw({ commit }, state) {
      commit("setDraw", state);
    },
    setOver({ commit }, over) {
      commit("setOver", over);
    },
    setMessages({ commit }, message) {
      commit("setMessages", message);
    },
  },
  getters: {
    getBeforeRoute(state) {
      return state.beforeRoute;
    },
    getDraw(state) {
      return state.draw;
    },
    getOver(state) {
      return state.over;
    },
    getMessages(state) {
      return state.messages;
    },
  },
  modules: {
    auth,
    calendar,
    channel,
    message,
    product,
    comment,
    cart,
    card,
    membership,
    document,
    order,
    payment,
    blog
  },
});
