import store from "../store";
import moment from 'moment'

const URL_API =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4008/"
    : "https://enfoquelaboral.mx/";

const requestFetch = async (path, data) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", store.getters["auth/getToken"]);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: data ? "POST" : "GET",
    headers: myHeaders,
    body: JSON.stringify(data) || null,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${URL_API}${path}`, requestOptions);
    const data = await response.json();

    if (data.error) throw data.error;
    return data;
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export default {
  namespaced: true,
  state: {
    errors: [],
    events: []
  },
  mutations: {
    setEvents(state, events) {
      state.events = events
    },
    setErrors(state, error) {
      if (error == "clear") {
        state.errors = [];
      } else {
        state.errors.push(error);
      }
    },
  },
  actions: {
    //SET ERRORS
    setErrors({ commit }, error) {
      commit("setErrors", error);
    },
    // Eventos del usaurio
    async getEventsByUser({ commit }) {
      commit("setErrors", "clear");
      const response = await requestFetch("api/events/me");
      commit('setEvents', response.events)
      return response.events;
    },

    // detalle de un evento
    async getEventById({ commit }, id) {
      commit("setErrors", "clear");
      const response = await requestFetch(`api/events/${id}`);
      return response.event;
    },
  },
  getters: {
    getErrors(state) {
      return state.errors;
    },
    // eventos
    getEvents(state) {
      return state.events;
    },
    // eventos del dia actual
    getEventsToday(state) {
      // console.log(moment().format('YYYY-MM-DD HH:mm:ss'))

      return state.events.filter((item) => {
        // console.log(moment().format('DD-MM-YYYY'))
        // console.log(moment().utc().format('DD-MM-YYYY'))

        return moment(item.date_start).utc().format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')
      })
    },
  },
};
