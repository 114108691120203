import store from "../store";

const URL_API =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4008/"
    : "https://enfoquelaboral.mx/";

const requestFetch = async (path, data) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", store.getters["auth/getToken"]);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: data ? "POST" : "GET",
    headers: myHeaders,
    body: JSON.stringify(data) || null,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${URL_API}${path}`, requestOptions);
    const data = await response.json();

    if (data.error) throw data.error;
    return data;
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export default {
  namespaced: true,
  state: {
    errors: [],
    products: [],
  },
  mutations: {
    setErrors(state, error) {
      if (error == "clear") {
        state.errors = [];
      } else {
        state.errors.push(error);
      }
    },
    setProducts(state, products) {
      state.products = products;
    },
  },
  actions: {
    //SET ERRORS
    setErrors({ commit }, error) {
      commit("setErrors", error);
    },
    // GET PRODUCTS
    async getProducts({ commit }) {
      commit("setErrors", "clear");
      const response = await requestFetch("api/products");
      // console.log(response.products);
      commit("setProducts", response.products);
      return response.products;
    },

    // GET memberships
    // async getMemberships({ commit }) {
    //   commit("setErrors", "clear");
    //   const response = await requestFetch("/api/memberships");
    //   return response.memberships;
    // },
    // GET product by id
    // async getProuctById({ commit }, product_id) {
    //   commit("setErrors", "clear");
    //   const response = await requestFetch(`/api/products/${product_id}`);
    //   return response.product;
    // },
    async getProuctBySlug({ commit }, slug) {
      commit("setErrors", "clear");
      const response = await requestFetch(`api/products/slug/${slug}`);
      return response.product;
    },

    // GET product by id
    // async getDocumentsByProduct({ commit }, product_id) {
    //   commit("setErrors", "clear");
    //   const response = await requestFetch(
    //     `/api/products/${product_id}/documents`
    //   );
    //   console.log(response);
    //   return response.documents;
    // },
  },
  getters: {
    getErrors(state) {
      return state.errors;
    },
    getProducts(state) {
      return state.products.map((item) => {
        return { id: item.id, name: item.name, slug: item.slug };
      });
    },
    getProductsActive(state) {
      return state.products
        .filter((product) => product.is_active)
        .map((item) => {
          return { id: item.id, name: item.name, slug: item.slug };
        });
    },
  },
};
