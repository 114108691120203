import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import AppLayout from "./layouts/AppLayout";
require('dotenv').config()
import "css-file-icons";

import vuetify from "./plugins/vuetify";
import "@babel/polyfill";

import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";


// google

import GAuth from 'vue-google-oauth2'
const gauthOption = {
  clientId:
      "911169237362-bq3urrntkuuge75luoe94oqkv2lvdq40.apps.googleusercontent.com",
  scope: "profile email",
  prompt: "select_account",
};
Vue.use(GAuth, gauthOption)

// header vue
import VueHead from 'vue-head'
Vue.use(VueHead)

Vue.mixin({
  methods: {
    formatNumber(a) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })
      var h = formatter.format(a).split('$')
      return ' $ ' + ' ' + h[1];
    },
    reformatDate(d) {

      if (d) {
        var da = new Date(d);
        var day = da.getDate();
        var month = da.getMonth() + 1;
        var year = da.getFullYear();
        let dayfinal;
        let monthfinal;
        if (day < 10) {
          dayfinal = '0' + day
        } else {
          dayfinal = day
        }
        if (month < 10) {
          monthfinal = '0' + month
        } else {
          monthfinal = month
        }
        return dayfinal + '-' + monthfinal + '-' + year;
      }

    },
    statusOrder(status) {
      let state;
      switch (status) {
        case 'order_draft':
          state = 'Orden Realizada en espera';
          break
        case 'order_received':
          state = 'Pago Recibido';
          break;
        case 'order_authorized':
          state = 'Pago Autorizado';
          break;
        case 'order_shipped':
          state = 'Orden Enviada';
          break;
        case 'order_shipped_received':
          state = 'Orden Recibida';
          break;
        case 'order_problem':
          state = 'Orden Con problemas';
          break;
      }
      return state
    },

  }
})



Vue.component("AppLayout", AppLayout);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
