import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Service from '../views/Service.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home"),
    meta: {
      layout: "WebLayout",
    },
  },
  {
    path: "/notas-relevantes",
    name: "NotasRelevantes",
    component: () => import("../views/NotasRelevantes"),
    meta: {
      layout: "WebLayout",
    },
  },
  {
    path: "/nota/:slug",
    name: "Nota",
    component: () => import("../views/Nota"),
    meta: {
      layout: "WebLayout",
    },
  },

  {
    path: "/servicio/:slug",
    name: "Service",
    component: Service,
    meta: {
      layout: "WebLayout",
    },
  },
  {
    path: "/nosotros",
    name: "Us",
    component: () => import("../views/Us"),
    meta: {
      layout: "WebLayout",
    },
  },
  {
    path: "/contacto",
    name: "Contact",
    component: () => import("../views/Contact"),
    meta: {
      layout: "WebLayout",
    },
  },
  {
    path: "/calculadora-finiquito",
    name: "Calculator",
    component: () => import("../views/Calculator.vue"),
    meta: {
      layout: "WebLayout",
    },
  },
  {
    path: "/cart/detail",
    name: "Cart",
    component: () => import("../views/Cart.vue"),
    meta: {
      layout: "WebLayout",
    },
  },
  {
    path: "/pagar-servicio",
    name: "MembershipCheckout",
    component: () => import("../views/MembershipCheckout.vue"),
    meta: {
      layout: "WebLayout",
    },
  },
  {
    path: "/auth",
    component: () => import("../layouts/AuthLoyout.vue"),
    redirect: { name: "Login" },
    meta: {
      layout: "WebLayout",
    },
    children: [
      {
        path: "login",
        name: "Login",

        component: () => import("../views/Login.vue"),
        meta: {
          layout: "WebLayout",
        },
      },
      {
        path: "register",
        name: "Register",
        component: () => import("../views/Register.vue"),
        meta: {
          layout: "WebLayout",
        },
      },

    ],
  },

  {
    path: "/forget-password",
    name: "forget_password",
    component: () => import("../views/forget_password.vue"),
    meta: {
      layout: "WebLayout",
    },
  },
  {
    path: "/dash",
    name: "Dashboard",
    redirect: { name: "MyProfile" },
    component: () => import("../views/dashboard/Dashboard.vue"),
    meta: {
      layout: "DashboardLayout",
      isAuthenticated: true,
    },
  },
  {
    path: "/asesoria_express",
    name: "AdvisoryExpress",
    component: () => import("../views/dashboard/AdvisoryExpress.vue"),
    meta: {
      layout: "DashboardLayout",
      isAuthenticated: true,
    },
  },
  {
    path: "/mi-perfil",
    name: "MyProfile",
    component: () => import("../views/dashboard/MyProfile.vue"),
    meta: {
      layout: "DashboardLayout",
      isAuthenticated: true,
    },
  },
  {
    path: "/events",
    name: "MyCalendary",
    component: () => import("../views/dashboard/MyCalendary.vue"),
    meta: {
      layout: "DashboardLayout",
      isAuthenticated: true,
    },
  },
  {
    path: "/events/:id",
    name: "EventDetail",
    component: () => import("../views/dashboard/EventDetail.vue"),
    meta: {
      layout: "DashboardLayout",
      isAuthenticated: true,
    },
  },
  {
    path: "/chat",
    name: "Chat",
    component: () => import("../views/dashboard/Chat.vue"),
    meta: {
      layout: "DashboardLayout",
      isAuthenticated: true,
    },
  },
  {
    path: "/mis-pagos",
    name: "MyPayments",
    component: () => import("../views/dashboard/MyPayments.vue"),
    meta: {
      layout: "DashboardLayout",
      isAuthenticated: true,
    },
  },
  {
    path: "/mis-documentos",
    name: "MyDocuments",
    component: () => import("../views/dashboard/MyDocuments.vue"),
    meta: {
      layout: "DashboardLayout",
      isAuthenticated: true,
    },
  },
  {
    path: "/mi-membresia",
    name: "Mymembership",
    component: () => import("../views/dashboard/Mymembership.vue"),
    meta: {
      layout: "DashboardLayout",
      isAuthenticated: true,
    },
  },
  {
    path: "/dashboard",
    name: "MyEventsToday",
    component: () => import("../views/dashboard/MyEventsToday.vue"),
    meta: {
      layout: "DashboardLayout",
      isAuthenticated: true,
    },
  },
  {
    path: "/editar-perfil",
    name: "UpdateProfile",
    component: () => import("../views/dashboard/UpdateProfile.vue"),
    meta: {
      layout: "DashboardLayout",
      isAuthenticated: true,
    },
  },
  {
    path: "*",
    name: "404",
    component: () => import("../views/404.vue"),
    meta: {
      layout: "WebLayout",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {

  if (to.meta.isAuthenticated) {
    await store.dispatch('auth/userme')
    await store.dispatch('membership/getMyMembership')
    // console.log("privada");
    // await store.dispatch["auth/userme"];
    // await store.dispatch.auth['/userme'];
    // console.log(store.getters["membership/getMembership"])

    if (!store.getters["auth/getUser"]) {
      // console.log("nno loqguado");
      next({ name: "Login" });
    } else {
      // console.log("estas loqguado");
      next();
      if (window.screen.width <= 960) {
        store.dispatch('setDraw', false)
        // console.log('mobil')
      }
      else {
        store.dispatch('setDraw', true)
        // console.log('desk')
      }
    }
  } else {

    // no hacer login ni register si ya esta autenticado
    if ((to.name === 'Login' && store.getters["auth/getUser"]) || (to.name === 'Register' && store.getters["auth/getUser"])) {
      next(router.back());
      // console.log('redirigir a la vista annterio')

    } else {
      next();

    }

    // await store.dispatch["membership/getMyMembership"];
    // console.log(store.getters["membership/getMembership"])


    // if (to.name === 'MembershipCheckout' && store.getters["membership/getMembership"]) {
    //   next({ name: "Mymembership" });
    //   console.log('tengo una membresia')
    // } else {
    //   console.log('realiza el pago')
    //   console.log(store.getters["membership/getMembership"])
    //   next()
    // }

    // console.log("libre");
  }

  if (to.meta.isLogout) {
    if (store.getters["auth/getUser"]) {
      next({ name: "Home" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
