export const formatCurrencyIso = (value) => {
    return new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN", }).format(value)
}
export const formatCurrency = (value) => {
    return new Intl.NumberFormat("es-MX", {}).format(value)
}

export const formaDateExpCard = (value) => {
    if (value.length <= 0) {
        return
    }
    let cc = value.split("/").join("");
    cc = cc.match(new RegExp('.{1,2}$|.{1,2}', 'g')).join("/");
    console.log(cc)
    return cc;
}