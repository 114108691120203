import store from "../store";

const URL_API =
    process.env.NODE_ENV === "development"
        ? "http://localhost:4008/"
        : "https://enfoquelaboral.mx/";

const requestFetch = async (path, data) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", store.getters["auth/getToken"]);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: data ? "POST" : "GET",
        headers: myHeaders,
        body: JSON.stringify(data) || null,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${URL_API}${path}`, requestOptions);
        const data = await response.json();

        if (data.error) throw data.error;
        return data;
    } catch (error) {
        console.log(error);
        return { error };
    }
};

export default {
    namespaced: true,
    state: {
        productSelected: null,
        errors: [],
    },
    mutations: {
        setErrors(state, error) {
            if (error == "clear") {
                state.errors = [];
            } else {
                state.errors.push(error);
            }
        },
        setProductSelected(state, product) {
            console.log(product)
            state.productSelected = product
        },
    },
    actions: {
        //SET ERRORS
        setErrors({ commit }, error) {
            commit("setErrors", error);
        },
        addProductSelected({ commit }, product) {
            commit('setProductSelected', product)
        }

    },
    getters: {
        getErrors(state) {
            return state.errors;
        },
        getProductSelected(state) {
            return state.productSelected;
        },
    },
};
