<template>
  <div v-if="product">
    <div class="carousel-services-area bg-gray">
      <div class="container-box oh">
        <div class="carousel-service-items owl-carousel owl-theme">
          <div class="item">
            <div class="row">
              <div class="col-md-6 px-0 py-0">
                <img src="../assets/img/service/1.jpg" alt="" height="100%" />
              </div>
              <div class="col-md-6 info">
                <h2>{{ product.name }}</h2>
                <h5>{{ product.abbr }}</h5>
                <!-- <p
                  class="py-0 my-0"
                  v-for="(item, index) in services"
                  :key="index"
                >
                  o {{ item }} <br />
                </p> -->
                <div v-html="product.description"></div>
                <!-- <a href="#" class="btn btn-theme effect btn-sm">La Necesito</a> -->
                <!-- <a class="btn btn-theme effect btn-sm"></a> -->
                <pay-button
                  v-if="!hiddenBtnPay"
                  class="btn btn-pay btn-theme effect btn-sm white--text mt-4"
                  :product="product"
                >
                  <span class="white--text title-pay">Lo Necesito</span>
                </pay-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Services -->

    <!-- Start Faq
============================================= -->
    <div class="faq-area default-padding">
      <div class="container">
        <div class="row">
          <div class="col-md-12 faq-items">
            <!-- Start Accordion -->
            <div class="acd-items acd-arrow">
              <div class="panel-group symb" id="accordion">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#ac1"
                      >
                        ¿Que Obtengo en mi {{ product.name }}?
                      </a>
                    </h4>
                  </div>
                  <div id="ac1" class="panel-collapse collapse in">
                    <div class="panel-body">
                      <!-- <p
                        class="py-0 my-0"
                        v-for="(item, index) in services"
                        :key="index"
                      >
                        o {{ item }} <br />
                      </p> -->

                      <div v-html="product.product_benefit"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Accordion -->
          </div>
        </div>
      </div>
    </div>
    <!-- End Faq  -->

    <!-- Start Pricing Tables
============================================= -->
    <!-- <pre>{{ product }}</pre> -->
    <div
      v-if="product.is_membership"
      class="default-padding bg-gray bottom-less pricing-plane"
    >
      <div class="container">
        <div class="site-heading text-center">
          <div class="row justify-center">
            <div class="col-md-8">
              <h2>{{ product.name }}</h2>
              <p>Planes y Precios para tu comodidad</p>
            </div>
          </div>
        </div>
        <div class="pricing pricing-simple text-center">
          <div class="row justify-center">
            <PricingSimple :product="product" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PricingSimple from "../components/services/PricingSimple.vue";
import { mapActions, mapGetters } from "vuex";
import PayButton from "../components/PayButton.vue";
export default {
  components: { PricingSimple, PayButton },
  data() {
    return {
      product: null,
    };
  },

  computed: {
    ...mapGetters("membership", ["getMembership"]),
    hiddenBtnPay() {
      if (this.getMembership) {
        return this.getMembership.order.details.product.id == this.product.id;
      } else {
        return false;
      }
    },
  },

  async mounted() {
    this.setOver(true);
    const slug = this.$route.params.slug;
    const resp = await this.getProuctBySlug(slug);
    if (!resp) {
      this.$router.push({ name: "Home" });
    } else {
      this.product = resp;
      window.setTimeout(function () {
        self.title = "My async title";
        self.$emit("updateHead");
      }, 3000);
    }
    await this.getMyMembership();
    this.setOver(false);
  },
  head: {
    // To use "this" in the component, it is necessary to return the object through a function
    title: function () {
      return {
        inner: this.$route.params.slug,
      };
    },
  },

  methods: {
    ...mapActions("product", ["getProuctBySlug", "setErrors"]),
    ...mapActions(["setOver"]),
    ...mapActions("membership", ["getMyMembership"]),
  },
};
</script>

<style scoped>
.carousel-services-area .info {
  background-color: transparent !important;
}

.title-pay:hover {
  color: #FAB848 !important;
}

.btn-pay:hover .title-pay {
  color: #FAB848 !important;
}
</style>
