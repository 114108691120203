<template>
  <AppLayout>
    <router-view></router-view>
  </AppLayout>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  async mounted() {
    await this.getProducts();
    // console.log("membresia");
    // console.log("hola");
    // console.log(this.getUser);
    // si estas autenticado
    // if (this.getUser) {
    // await this.getMyMembership();
    // }
    //   console.log("no estas autenticado");
    //   await this.getMyMembership(null);
    // }
    // setInterval(() => {
    //   this.alerteEvent();
    // }, 2000);
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
  },

  methods: {
    ...mapActions("product", ["getProducts"]),
  },
};
</script>
<style>
body {
  margin: 0;
  padding: 0;
}
</style>
