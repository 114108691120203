<template>
  <div>
    <a @click="addServiceTocart"><slot></slot></a>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title class="text-h4 text-center"
          >Ya posees un servicio, si deseas adquirir uno nuevo debes cacelar la
          suscripción actual</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-h6 font-weight-bold"
            color="grey darken-1"
            text
            @click="dialog = false"
            >Cerrar</v-btn
          >
          <v-btn
            class="text-h6 font-weight-bold"
            color="primary darken-1"
            depressed
            @click="cancelSuscription"
            >Calcelar servicio</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters("membership", ["getMembership"]),
  },
  methods: {
    cancelSuscription() {
      // this.$router.push({ name: "Mymembership" });
      window.location.href = "/mi-membresia";
    },
    addServiceTocart() {
      if (this.getMembership) {
        // console.log("tiene una membresia");
        this.dialog = true;
      } else {
        this.addProductSelected(this.product);

        if (this.getUser) {
          this.$router.push({ name: "MembershipCheckout" });
        } else {
          this.$router.push({ name: "Login", query: { pay: true } });
        }
      }
    },
    ...mapActions("cart", ["addProductSelected"]),
  },
};
</script>

<style lang="scss" scoped>
</style>