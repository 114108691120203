import store from "../store";
import moment from 'moment'

const URL_API =
    process.env.NODE_ENV === "development"
        ? "http://localhost:4008/"
        : "https://enfoquelaboral.mx/";

const requestFetch = async (path, data) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", store.getters["auth/getToken"]);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: data ? "POST" : "GET",
        headers: myHeaders,
        body: JSON.stringify(data) || null,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${URL_API}${path}`, requestOptions);
        const data = await response.json();

        if (data.error) throw data.error;
        return data;
    } catch (error) {
        console.log(error);
        return { error };
    }
};

export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {
        async getNotes({ commit }) {
            const response = await requestFetch("api/blog/web");
            return response.blogs;
        },
        async getRecent({ commit }) {
            const response = await requestFetch("api/blog/recent");
            return response.blogs;
        },
        async getSlugEvent({ commit },slug) {
            const response = await requestFetch(`api/blog/slug/${slug}`);
            return response.blog;
        },

    },
    getters: {

    },
};
